<template>
    <main>
        <div v-show="!pagination.data.length" class="alert alert-info">
            <i class="fa fa-info-circle fa-fw"> </i> No se encontraron datos para el paciente : {{ usuario.documento }}
        </div>
        <data-grid :data="pagination" @changePage="changePage($event)" @perPageChange="perPageChange($event)"
            @search="search($event)" :searchBox="false">
            <template #grid>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3" v-for="(item, index) in pagination.data"
                    :key="index">
                    <div class="card shadow-none">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h4 class="card-title">
                                        <button v-if="item.user_id === user.id || _can('deteled.prueba.rapida')"
                                            class="btn btn-outline-danger btn-icon btn-xs" title="Eliminar Resultado"
                                            @click="eliminarPrueba(item.id)">
                                            <i class="fa fa-trash-alt"></i>
                                        </button>
                                        {{ item.prueba.name }} - Trimestre : {{ item.trimestre }}
                                    </h4>
                                    <small>
                                        <i class="far fa-calendar text-bold"></i> Fecha : <span class="text-primary">{{
                                            item.fecha |
                                            formatDate }}</span>
                                        <i class="fas fa-pills text-bold ml-2"></i> Tipo de prueba : <span
                                            class="text-uppercase badge badge-primary-soft badge-marketing">{{
                                                item.tipo_prueba
                                            }}</span>
                                    </small>
                                    <div class="mt-2 small">
                                        <div>Casa comercial : {{ item.casa_comercial }}</div>
                                        <div>Lote: {{ item.lote }}</div>
                                        <div>Fecha Vencimiento: {{ item.fecha_vencimiento | formatDate }}</div>
                                    </div>

                                </div>
                                <div class="col-lg-4">
                                    <p class="card-text text-black">
                                        <i class="fa fa-poll-h fa-fw"></i>Resultado:
                                        <span class="text-italic">{{ item.resultado }}</span>
                                    </p>
                                    <p class="card-text"><i class="fa fa-comment-alt fa-fw"></i>Observaciones:
                                        <i>{{ item.observaciones }}</i>
                                    </p>
                                    <button class="btn btn-secondary btn-xs" @click="generatePdf(item.id)"><i class="fa fa-print fa-fw"></i>Imprimir</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-between bg-light border-top-0">
                            <div class="views"><i class="fas fa-hospital-alt fa-fw"></i> Sede:
                                {{ item.sede.NOMBRE_SEDE }}
                            </div>
                            <div class="stats">
                                <i class="fas fa-user-md fa-fw"></i> Profesional:
                                {{ item.user.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </data-grid>
    </main>
</template>
<script>
import Swal from "sweetalert2";
import Toast from "../../../../components/common/utilities/toast";
import pruebasRapidasUsuarioService from '../../../../services/pruebasRapidasUsuarioService';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
export default {
    props: ['usuario'],
    components:{DataGrid},
    data() {
        return {
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                    id_sede: "",
                },
            }
        }
    },
    methods: {
        async getPruebas() {
            try {
                if (!this.usuario?.id) return;
                this.LoaderSpinnerShow();
                const response = (await pruebasRapidasUsuarioService.showByIdUser(this.pagination.search, this.pagination, this.usuario.id))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (error) {

                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }
        },
        async eliminarPrueba(id) {
            const result = await Swal.fire({
                title: "",
                text: "¿Está seguro de eliminar este resultado de prueba rápida?",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                icon: "question",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        await pruebasRapidasUsuarioService.delete(id);
                    } catch (e) {
                        console.error(e);
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if (result.isConfirmed) {
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                this.getPruebas();
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getPruebas();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getPruebas();
        },
        generatePdf(id){
            window.open(pruebasRapidasUsuarioService.print(id));
        }
    },
    async created() {
        this.getPruebas();
    },
    watch: {
        usuario: {
            handler: function () {
                this.getPruebas();
            },
            deep: true
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>